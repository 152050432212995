<template>
  <v-container class="pt-0">
    <template v-if="recommendations && recommendations.length > 0">
      <back-toolbar :title="alltitle"></back-toolbar>
      <item-list
        :items="recommendations"
        :showSegmentedControl="false"
      ></item-list>
    </template>

    <error-image
      v-else
      :errorDialog="true"
      :text="$t('dashboard.noRecommendation')"
      imageName="norecommendation.svg"
    ></error-image>
  </v-container>
</template>

<script>
import ItemList from "@/components/recommendations/ItemList";
import BackToolbar from "@/components/app/common/BackToolbar";
import ErrorImage from "@/components/app/common/ErrorImage";
import { mapGetters } from "vuex";
export default {
  components: {
    BackToolbar,
    ItemList,
    ErrorImage,
  },
  computed: {
    recommendations() {
      return this.$store.state.recommendations.currentLocationRecommendations;
    },
    ...mapGetters("recommendations", ["allList"]),
    alltitle() {
      return `dashboard.recommendations`;
    },
  },
};
</script>
